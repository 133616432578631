module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ae2fcfe022ab451f405e8da7db037b1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-165870059-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":true,"firestore":false,"storage":false,"messaging":false,"functions":true,"performance":false},"credentials":{"apiKey":"AIzaSyDs0YHeCLG8xiOXSIhtvRXrJpkUKxgOCEI","authDomain":"geonet-me.firebaseapp.com","databaseURL":"https://geonet-me.firebaseio.com","projectId":"geonet-me","storageBucket":"geonet-me.appspot.com","messagingSenderId":"275007142037","appId":"1:275007142037:web:33c0d40bdce21b2bd3c39c","measurementId":"G-ZRVV81ZHE4"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"405678510802075"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
