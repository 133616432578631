// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-1-on-1-phone-call-tsx": () => import("./../../../src/pages/1-on-1-phone-call.tsx" /* webpackChunkName: "component---src-pages-1-on-1-phone-call-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-financial-planning-tsx": () => import("./../../../src/pages/financial-planning.tsx" /* webpackChunkName: "component---src-pages-financial-planning-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insurance-tsx": () => import("./../../../src/pages/insurance.tsx" /* webpackChunkName: "component---src-pages-insurance-tsx" */),
  "component---src-pages-meet-the-team-tsx": () => import("./../../../src/pages/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-tsx" */),
  "component---src-pages-personal-solutions-tsx": () => import("./../../../src/pages/personal-solutions.tsx" /* webpackChunkName: "component---src-pages-personal-solutions-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-retirement-solution-tsx": () => import("./../../../src/pages/retirement-solution.tsx" /* webpackChunkName: "component---src-pages-retirement-solution-tsx" */),
  "component---src-pages-smsf-tsx": () => import("./../../../src/pages/smsf.tsx" /* webpackChunkName: "component---src-pages-smsf-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

